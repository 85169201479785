import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Gallery from "@browniebroke/gatsby-image-gallery"
import "@browniebroke/gatsby-image-gallery/dist/style.css"

const getEmbedUrl = url => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[2].length === 11 ? match[2] : null
}

const Event = ({ data }) => {
  const { titel, createdAt, author, artikel, bilder, titelbild } = data.event

  let images = []
  bilder.map(bilder => {
    return images.push(bilder)
  })

  const Bold = ({ children }) => (
    <span className="has-text-weight-semibold	">{children}</span>
  )
  const Italic = ({ children }) => <span className="is-italic">{children}</span>
  const Text = ({ children }) => (
    <div className="align-center py-3">{children}</div>
  )
  const HEADING_1 = ({ children }) => <h1 className="title is-1">{children}</h1>
  const HEADING_2 = ({ children }) => <h2 className="title is-2">{children}</h2>
  const HEADING_3 = ({ children }) => <h3 className="title is-3">{children}</h3>
  const HEADING_4 = ({ children }) => <h4 className="title is-4">{children}</h4>
  const HEADING_5 = ({ children }) => <h5 className="title is-5">{children}</h5>
  const HEADING_6 = ({ children }) => <h6 className="title is-6">{children}</h6>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.HEADING_1]: (node, children) => <HEADING_1>{children}</HEADING_1>,
      [BLOCKS.HEADING_2]: (node, children) => <HEADING_2>{children}</HEADING_2>,
      [BLOCKS.HEADING_3]: (node, children) => <HEADING_3>{children}</HEADING_3>,
      [BLOCKS.HEADING_4]: (node, children) => <HEADING_4>{children}</HEADING_4>,
      [BLOCKS.HEADING_5]: (node, children) => <HEADING_5>{children}</HEADING_5>,
      [BLOCKS.HEADING_6]: (node, children) => <HEADING_6>{children}</HEADING_6>,
      [INLINES.HYPERLINK]: node => {
        if (node.data.uri.indexOf("youtube.com") !== -1) {
          return (
            <span className="">
              <div className="videoContainer my-5">
                <iframe
                  title="youtube-nocookie"
                  className="video"
                  width="560"
                  height="315"
                  src={`https://www.youtube-nocookie.com/embed/${getEmbedUrl(
                    node.data.uri
                  )}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </span>
          )
        } else
          return (
            <a
              href={node.data.uri}
              className="is-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {node.content[0].value}
            </a>
          )
      },
    },
  }

  return (
    <Layout
      title={titel}
      // secondTitle="Eine Veranstaltung der WirtschaftsPlattForm Schwechat"
      fluid={titelbild.fluid}
      seoTitle={`WirtschaftsPlattForm Schwechat | Veranstaltung - ${titel}`}
      alt={titel}
    >
      <section className="section">
        <div className="container">
          <article className="is-size-5 content has-text-dark">
            <h4 className="title is-4">{titel}</h4>
            {renderRichText(artikel, options)}
            <br />
            <p className="is-size-6 has-text-right">{author}</p>
            <p className="is-size-6 has-text-right">{createdAt}</p>
          </article>
          <h4 className="title is-4">Gallerie</h4>
          <Gallery images={images} />
        </div>
      </section>
      <div className="section">
        <div className="container has-text-centered">
          <Link to="/veranstaltungen/" className="button is-link is-medium">
            Zur Übersicht
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query getEvent($url: String!) {
    event: contentfulVeranstaltung(url: { eq: $url }) {
      titel
      author
      artikel {
        raw
      }
      createdAt(formatString: "MMM DD, YYYY", locale: "de")
      titelbild {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      bilder {
        id
        thumb: fluid(maxWidth: 270, maxHeight: 270) {
          ...GatsbyContentfulFluid_withWebp
        }
        full: fluid(maxWidth: 1024) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default Event
